import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

const mutations = {
    setIsMobile(state, isMobile) {
        state.isMobile = isMobile
    },
    handleUserName: (state, user) => {
        state.userName = user
    },
    handleUserId: (state, userId) => {
        state.userId = userId
    },
    handleUserToken: (state, userToken) => {
        state.userToken = userToken
    },
    collapseMenu(state) {
        if(!state.isMobile){
            state.isCollapse = !state.isCollapse
        }else{
            state.isCollapse = false
            state.showDrawerMenu = !state.showDrawerMenu
        }
    },
    //选择标签 选择面包屑
    selectMenu(state, val) {
        if (val.name === 'home') {
            state.currentMenu = null
        } else {
            state.currentMenu = val
                //如果等于-1说明tabsList不存在那么插入，否则什么都不做
            let result = state.tabsList.findIndex(item => item.name === val.name)
            result === -1 ? state.tabsList.push(val) : ''

        }
        // val.name === 'home' ? (state.currentMenu = null) : (state.currentMenu = val)
    },
    //关闭标签
    closeTab(state, val) {
        let result = state.tabsList.findIndex(item => item.name === val.name)
        state.tabsList.splice(result, 1)
    },
}
const actions = {
    handleUserName: (ctx, user) => {
        ctx.commit('handleUserName', user)
    },
    handleUserId: (ctx, userId) => {
        ctx.commit('handleUserId', userId)
    },
    handleUserToken: (ctx, userToken) => {
        ctx.commit('handleUserToken', userToken)
    },
}

const state = {
    userName: '',
    userToken: '',
    userId: '',
    isCollapse: false,
    showDrawerMenu: false,
    isMobile: window.innerWidth < 1140 ? true : false,
    tabsList: [{
        uri: 'home/entry',
        name: '首页',
    }]
}
const store = new Vuex.Store({
    namespaced: true,
    actions,
    state,
    mutations,
    plugins: [createPersistedState({
        storage: window.sessionStorage,
        reducer(data) {
            return {
                userName: data.userName,
                userToken: data.userToken,
                userId: data.userId,
                tabsList: data.tabsList
            }
        }
    })]
})

export default store