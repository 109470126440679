<template>
    <el-menu :collapse="isCollapse" background-color="#343a40" :unique-opened=true text-color="#fff"
        active-text-color="#ffd04b" :default-active="onRoutes" class="el-menu-vertical-demo" router>
        <div v-if="!$store.state.isMobile" class="brand-link">
            <img src="../../../assets/image/logo.png" class="brand-image">
            <span v-if="!isCollapse">小熊后台</span>
        </div>
        <template v-for="item in items">
            <template v-if="item.children">
                <el-submenu :index="item.uri" :key="item.id">
                    <template slot="title">
                        <i :class="item.icon"></i>
                        <span>{{ item.name }}</span>
                    </template>
                    <el-menu-item-group>
                        <template v-for="sub in item.children">
                            <template v-if="sub.children">
                                <el-submenu :index="sub.uri" :key="sub.id">
                                    <template slot="title">
                                        <i :class="sub.icon"></i>
                                        <span>{{ sub.name }}</span>
                                    </template>
                                    <el-menu-item-group>
                                        <el-menu-item v-for="isub in sub.children" :index="isub.uri"
                                            @click="clickMenu(isub)">
                                            <span slot="title">{{ isub.name }}</span>
                                        </el-menu-item>
                                    </el-menu-item-group>
                                </el-submenu>
                            </template>
                            <template v-else>
                                <el-menu-item :index="sub.uri" :key="sub.id" @click="clickMenu(sub)">
                                    <span slot="title">{{ sub.name }}</span>
                                </el-menu-item>
                            </template>
                        </template>
                    </el-menu-item-group>
                </el-submenu>
            </template>
            <template v-else>
                <el-menu-item :index="item.uri" @click="clickMenu(item)">
                    <i :class="item.icon"></i>
                    <span slot="title">{{ item.name }}</span>
                </el-menu-item>
            </template>
        </template>
    </el-menu>
</template>
<script>
import {
    mapState
} from 'vuex'
import routers from '@/router/routers'
import * as api from '@/config/api'
export default {
    name: 'Nav',

    data() {
        return {
            // isCollapse: true,
            //配置目录
            items: [],

        }
    },
    computed: {
        onRoutes() {
            return '/'
        },
        isCollapse() {
            // 这里的数据就是从vuex取得
            return this.$store.state.isCollapse
        },
    },
    mounted() {
        this.getMenu()
    },
    methods: {
        //跳转路由 根据名称跳转
        clickMenu(item) {
            let user = JSON.parse(localStorage.getItem("messageStore"))
            if (item.uri.indexOf('http') != -1) {
                console.log(item.uri)
                window.open(item.uri + '?userName=' + user.userName + '&userId=' + user.userId + '&userToken=' + user.userToken)
            } else {
                this.$store.commit('selectMenu', item)
                this.$router.push({
                    path: item.uri
                })
            }
        },
        getMenu() {
            api.getUserMenu(res => {
                this.items = res.data
                // console.log(res.data)
                this.$nextTick(() => {
                    this.change(this.items)
                })
            })
        },
        steamroller(arr) {
            var newArr = [];
            arr.some(element => {
                newArr.push(element)
                if (element.children) {
                    newArr.push.apply(newArr, this.steamroller(element.children))
                    delete element.children
                }
            });
            return newArr
        },
        change(data) {

            let list = this.steamroller(data)
            let tab = this.$store.state.tabsList
            let tabsList = tab.filter(item => list.some(el => el.id == item.id))
            let home = [{
                name: '首页',
                uri: '/home/entry'
            }]
            let messageStire = JSON.parse(localStorage.getItem("messageStore"))
            messageStire.tabsList = [...home, ...tabsList]
            messageStire = [...messageStire, ...this.$store.state]
            window.localStorage.setItem('messageStore', JSON.stringify(messageStire))
            this.$store.replaceState(Object.assign(this.$store.state, JSON.parse(localStorage.getItem("messageStore"))))
        }
    }
}
</script>
<style>
.el-menu {
    height: 100%;
    border-right: none;
}

.el-aside {
    box-sizing: border-box;
    flex-shrink: 0;
    height: 100vh;
}

.brand-link {
    display: block;
    font-size: 1.25rem;
    line-height: 1.5;
    padding: .8125rem .5rem;
    transition: width .3s ease-in-out;
    white-space: nowrap;
    border-bottom: 1px solid #4b545c;
    color: #fff;
}

.brand-link .brand-image {
    line-height: .8;
    margin-left: .8rem;
    margin-right: .5rem;
    margin-top: -3px;
    max-height: 33px;
    width: auto;
    vertical-align: middle;
}

.el-submenu__title,
.el-menu-item {
    display: flex;
    align-items: center;
}

.el-menu-item i,
.el-submenu__title i {
    color: #fff;
    margin-right: 5px;
}

.el-menu-item.is-active {
    color: #409EFF !important;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
}
</style>