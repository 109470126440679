<template>
  <div id="app">
    <keep-alive>
        <router-view/>
    </keep-alive>
  </div>
</template>

<script>
    export default {
        name: 'App',
        created() {
            //在页面刷新时将vuex里的信息保存到localStorage里
            window.addEventListener("beforeunload", () => {
                localStorage.setItem("messageStore", JSON.stringify(this.$store.state))
            })

            //在页面加载时读取localStorage里的状态信息
            localStorage.getItem("messageStore") && this.$store.replaceState(Object.assign(this.$store.state, JSON.parse(localStorage.getItem("messageStore"))));
        }
    }
</script>

<style>
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        /* margin-top: 60px; */
    }
    
    body {
        margin: 0;
        overflow: hidden;
    }
    
    .content {
        padding: 1rem 1rem 0;
    }

    .mini-cell{
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        padding: 5px 10px;
        }
        .mini-cell+.mini-cell{
        border-top: 1px solid #f0f0f0;
        }
        .mini-cell .mini-label{
        color: #666;
        }
        .mini-cell .mini-value{
        color: #333;
        }
</style>