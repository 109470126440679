<template>
    <el-container class="m-contanier">
        <!-- 导航 -->
        <el-aside v-if="!$store.state.isMobile" width="auto">
          <Nav></Nav> 
        </el-aside>
        <el-drawer
          size="200px"
          title="小熊后台"
          :visible.sync="$store.state.showDrawerMenu"
          direction="ltr"
        >
        <div style="overflow-y: auto;height: calc(100vh - 79px);">
          <Nav></Nav> 
        </div>
        </el-drawer>
        <!-- 导航结束 -->
        <el-container>
          <!-- 头部 -->
          <el-header>
            <Header></Header>
          </el-header>
          <!-- 头部结束 -->
            <el-main>
              <Tab></Tab>
              <keep-alive exclude="activitySnatchTreasureActivity,activitySnatchTreasureMdms,associationData,accountJurisdiction">
                <router-view></router-view>
              </keep-alive>
            </el-main>
            <Footer></Footer>
          </el-container>
          <!-- 底部 -->
         
          <!-- 底部结束 -->
    </el-container>
</template>
<script>
    import Header from '@/pages/public/index/header'
    import Nav from '@/pages/public/index/nav'
    import Footer from '@/pages/public/index/footer'
    import Tab from '@/pages/public/index/tab'
    import * as api from '@/config/api'
    export default {
        components: {
            Header,
            Tab,
            Nav,
            Footer,
        },
        watch: {
          '$store.state': {handler: (newVal, oldVal) => {
            console.log(JSON.stringify(newVal), 'new', JSON.stringify(oldVal), 'old')
          },
          deep: true}
        },
        mounted() {
          console.log(window.innerWidth < 1140)
          console.log(this.$store.state.isMobile, '1')
          this.$store.commit('setIsMobile', window.innerWidth < 1140)
          console.log(this.$store.state.isMobile, '2')
        },
        methods: {}
    }
</script>
<style scoped>
    .m-contanier{
      height: 100vh;
    }
    .el-main {
        height: calc(100vh - 120px);
        background-color: #f4f6f9;
        padding: 0;
        overflow: scroll;
        overflow-x: hidden;
    }
</style>