<template>
  <!-- Main content -->
  <section class="content">
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="dialog = true"
        >添加</el-button
      >
    </div>
    <el-table v-if="!$store.state.isMobile" v-loading="loading" :data="blackList" border>
      <el-table-column fixed prop="id" label="编号" width="100">
      </el-table-column>
      <el-table-column label="用户ID" width="250">
        <template slot-scope="scope">
          {{ scope.row.user_id }}({{ scope.row.user_key }})
        </template>
      </el-table-column>
      <el-table-column label="创建时间">
        <template slot-scope="scope">
          {{ scope.row.create_time | dateTime }}
        </template>
      </el-table-column>
      <el-table-column label="更新时间">
        <template slot-scope="scope">
          {{ scope.row.update_time | dateTime }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="danger" size="mini" @click="del(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div v-else>
      <el-card  v-for="(row, index) in blackList" :key="index">
        <div class="mini-cell">
          <div class="mini-label">编号</div>
          <div class="mini-value">{{ row.id }}</div>
        </div>
        <div class="mini-cell">
          <div class="mini-label">用户ID</div>
          <div class="mini-value">{{ row.user_id }}({{ row.user_key }})</div>
        </div>
        <div class="mini-cell">
          <div class="mini-label">创建时间</div>
          <div class="mini-value">{{ row.create_time | dateTime }}</div>
        </div>
        <div class="mini-cell">
          <div class="mini-label">更新时间</div>
          <div class="mini-value">{{ row.update_time | dateTime }}</div>
        </div>
        <div class="mini-cell">
          <el-button type="danger" size="mini" @click="del(row.id)"
            >删除</el-button
          >
          </div>
        </el-card>
    </div>
    <el-pagination
      background
      :layout="$store.state.isMobile ? 'pager' : 'total, sizes,prev, pager, next,jumper'"
      :total="total"
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>

    <el-dialog
      title="修改"
      :visible.sync="dialog"
      :close-on-click-modal="false"
    >
      <el-form>
        <el-form-item label="账号">
          <el-input v-model="user_id" placeholder="请输入用户ID"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="add">确 定</el-button>
      </div>
    </el-dialog>
  </section>
  <!-- /.content -->
</template>

<script>
import { getWhiteList, delWhite, addWhite } from '@/config/detailManage';
export default {
  name: 'blackList',
  components: {},

  data() {
    return {
      blackList: [],
      total: 0,
      page: 1,
      pagesize: 10,

      dialog: false,
      user_id: '',
      loading: true,
    };
  },
  watch: {},
  filters: {
    status(val) {
      let common = JSON.parse(window.localStorage.getItem('common'));
      let name = '';
      if (JSON.stringify(common.order_status_list).indexOf(val) != -1) {
        common.order_status_list.map((a, index) => {
          if (val === a.type) {
            name = a.name;
          }
        });
      }
      return name;
    },
    dateTime(val) {
      let day = new Date(Number(val) * 1000);
      if (Number(val) === 0) {
        return '';
      }
      return (
        day.getFullYear() +
        '-' +
        `${
          day.getMonth() + 1 < 10
            ? '0' + (day.getMonth() + 1)
            : day.getMonth() + 1
        }` +
        '-' +
        `${day.getDate() < 10 ? '0' + day.getDate() : day.getDate()}` +
        ' ' +
        `${day.getHours() < 10 ? '0' + day.getHours() : day.getHours()}` +
        ':' +
        `${day.getMinutes() < 10 ? '0' + day.getMinutes() : day.getMinutes()}` +
        ':' +
        `${day.getSeconds() < 10 ? '0' + day.getSeconds() : day.getSeconds()}`
      );
    },
  },
  mounted() {
    this.getWhiteList();
  },
  methods: {
    close() {
      this.show = false;
      this.getWhiteList();
    },
    async getWhiteList(page, size) {
      this.loading = true;
      const { data } = await getWhiteList({
        page: page ? page : this.page,
        pagesize: size ? size : this.pagesize,
      });
      this.blackList = data.list;
      this.total = Number(data.count);
      this.loading = false;
    },
    sizeChange(val) {
      this.pagesize = val;
      this.getWhiteList();
    },
    currentChange(val) {
      this.page = val;
      this.getWhiteList();
    },
    seach(page, size) {
      this.getWhiteList(page, size);
    },
    async del(id) {
      await this.$confirm('请确定是否删除该白名单', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      });
      const { msg } = await delWhite(id);
      this.$message({
        type: 'success',
        message: msg,
      });
      this.getWhiteList();
    },
    async add() {
      const { msg } = await addWhite(this.user_id);
      this.$message({
        type: 'success',
        message: msg,
      });
      this.dialog = false;
      this.getWhiteList();
    },
  },
};
</script>
<style scoped>
.cell {
  text-align: center;
}

.btn-box {
  text-align: left;
  margin-bottom: 20px;
}

img {
  width: 150px;
}
</style>
<style>
.mini-cell{
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  padding: 5px 10px;
}
.mini-cell+.mini-cell{
  border-top: 1px solid #f0f0f0;
}
.mini-cell .mini-label{
  color: #666;
}
.mini-cell .mini-value{
  color: #333;
}
</style>
